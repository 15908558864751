@import '@styles';

.product-activation {
    flex-grow: 1;

    @include screen(lg) {
        flex-grow: 0;
    }

    &__main {
        @include palette(bg accent 800, font accent 400);
        @include spaces(p 8);
        //@include rounded(t 16);
        width: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include screen(lg) {
            @include spaces(m 8, lw 220, mw 220, mh 420, lh 420);
            @include rounded(a 16);
            height: 680px;
        }

        @include screen(xl) {
            @include spaces( mh 400, lh 400);
            height: 680px;
        }
    }

    &__title {
        @include spaces(p 6);
        width: 100%;

        @include screen(xl) {
            @include spaces(pb 0);
        }

        & > div {
            @include palette(font accent 0);
            @include spaces(g 8);
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            align-items: center;
            width: 100%;
        }

        &__logo {
            & > svg {
                @include spaces(w 70, h 20);
            }
        }

        &__arrow {
            @include spaces(mr auto);
            justify-content: flex-start;
        }

        &__close {
            @include spaces(ml auto);
            align-items: flex-end;
            justify-content: flex-end;
        }
    }

    &__content {
        @include spaces(g 8, p 8);
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        width: 100%;

        @include screen(xl) {
            @include spaces(pt 0);
        }

        &__title {
            @include spaces(p 8, g 8);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;

            @include screen(xl) {
                @include spaces(p 0);
            }

            & > h1 {
                @include palette(font accent 0);
                @include fonts(size 16, line 18);
                font-weight: 800;
            }

            & > i {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                svg {
                    @include spaces(w 100, h 80);
                    
                    @include screen(lg) {
                        @include spaces(w 70, h 60);
                    }
                }
            }

            & > h2 {
                @include fonts(line 11);
            }
        }

        button {
            @include spaces(px 10);
            @include fonts(size 8.5);
            font-weight: bold;
            width: 100%;

            @include screen(lg) {
                width: auto;
            }
        }
    }

    &__options {
        @include spaces(g 10);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        list-style: none;
        flex-grow: 1;
    }

    &__option {
        @include spaces(g 8);
        @include fonts(line 11);
        display: flex;
        align-items: flex-start;
        width: 100%;

        & > i {
            @include palette(font accent 0);
        }

        div {
            @include spaces(g 2);
            display: flex;
            flex-direction: column;
            width: 100%;

            & > h3 {
                @include palette(font accent 0);
                font-weight: 600;
            }
        }
    }
}
