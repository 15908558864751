@import '@styles';

$apply-field-field-roles: primary success warning danger;

// label
@mixin label-roles($label) {
    #{$label} {
        @include palette(font light base-dark);
        //@include palette(font main gray-2);

        @include theme(dark) {
            @include palette(font light gray-3);
        }
    }

    &:focus-within {
        #{$label} {
            @include palette(font light base-dark);
            //@include palette(font main gray-3);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$label} {
            @include palette(font light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font light danger);
            }
        }

        &:focus-within {
            #{$label} {
                @include palette(font light danger);
                //@include palette(font main danger);

                @include theme(dark) {
                    @include palette(font light danger);
                }
            }
        }
    }
}

// content
@mixin content-roles($content) {
    #{$content} {
        @include palette(bg light base, border light gray);

        @include theme(dark) {
            @include palette(bg light base-dark, border light base-dark);
        }
    }

    &:focus-within {
        #{$content} {
            @include palette(border main gray-2);

            @include theme(dark) {
                @include palette(border main gray-3);
            }
        }
    }

    &--#{danger} {
        #{$content} {
            @include palette(border light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(border main danger);
            }
        }
    }

    @each $key in $apply-field-field-roles {
        &--#{$key} #{$content}--keep {
            @include palette(border main $key);
            //@include palette(border main $key);

            @include theme(dark) {
                @include palette(border main $key);
            }
        }

        &--#{$key}:focus-within {
            #{$content} {
                @include palette(border main $key);

                @include theme(dark) {
                    @include palette(border main $key);
                }
            }
        }
    }
}

// hint
@mixin hint-roles($hint) {
    #{$hint} {
        @include palette(font light base-dark);
        //@include palette(font main gray-3);

        @include theme(dark) {
            @include palette(font light gray-2);
        }
    }

    &:focus-within {
        #{$hint} {
            @include palette(font main base-dark);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$hint} {
            @include palette(font light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font light danger);
            }
        }
    }

    @each $key in $apply-field-field-roles {
        &--#{$key}:focus-within {
            #{$hint} {
                @include palette(font light $key);
                //@include palette(font main $key);

                @include theme(dark) {
                    @include palette(font light $key);
                }
            }
        }
    }
}

.file-field {
    $parent: &;
    $label: none;
    $content: none;
    $hint: none;

    align-items: flex-start;
    border-style: none;
    display: flex;
    flex-direction: column;
    position: relative;

    &--fit {
        max-width: fit-content;
    }

    &__label {
        $label: &;

        @include fonts(line 24);
        cursor: pointer;
        font-weight: 600;
    }

    &__content {
        $content: &;

        @include palette(border light gray, bg light base);
        @include spaces(g 8, b 1, p 12);
        @include rounded(a 6);
        display: grid;
        //grid-template-columns: 1.5fr 1fr;
        align-items: center;
        //justify-content: center;
        position: relative;
        width: 100%;
        height: 10rem;

        &--disabled {
            opacity: 0.6;
        }

        /* span {
            @include fonts(line 12);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        } */

        &__placeholder {
            @include spaces(g 10);
            display: grid;
            grid-template-columns: 1.5rem 1fr;
            align-items: center;

            & > i {
                @include palette(font main warning);
                @include fonts(size 22, line 26);
            }

            & > span {
                @include fonts(line 10);
            }

            &--success {
                & > i {
                    @include palette(font main success);
                }
            }

            &--danger {
                & > i {
                    @include palette(font main danger);
                }
            }
        }

        input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }

        &:hover,
        &__dragover {
            opacity: 0.6;
            //display: none;
        }

        &__disabled {
            opacity: 0.6;
            cursor: auto;

            input {
                cursor: auto;
            }
        }
    }

    &__hint {
        $hint: &;

        @include fonts(size 6.5);
        @include spaces(my 1);
        font-weight: 500;
        z-index: 1;
    }

    &__reserved {
        @include spaces(h 10, mt 1);
    }

    // label
    @include label-roles($label);
    // content
    @include content-roles($content);
    // hint
    @include hint-roles($hint);
}
