@import '@styles';

.person-verification {
    @include palette(bg light accent, font main base-dark);
    @include spaces(p 8, m 8);
    @include rounded(a 16);
    //@include rounded(t 16);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include screen(xl) {
        @include spaces(m 8, lw 300, mw 300);
    }

    &__title {
        @include spaces(p 8, g 8);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        & > h1 {
            @include fonts(size 16, line 18);
        }

        & > i {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    &__content {
        @include spaces(g 8, p 8);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        fieldset {
            width: 100%;
        }
    }
}
