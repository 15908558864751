@import '@styles';

.badge {
    @include palette(bg main danger, font light accent);
    @include fonts(size 6, line 8);
    @include spaces(p 4, ml 6);
    border-radius: 50%;
    position: relative;
    z-index: 99;

    &__center {
        @include spaces(ml 6);
    }

    &__top {
        position: absolute;
        bottom: calc(100% + 5px);
    }

    &__bottom {
        position: absolute;
        bottom: unset !important;
        top: calc(100% + 5px);
    }

    &__left {
        position: absolute;
        left: unset;
        top: calc(50% + 0.5rem);
        //top: 50%;
        right: calc(100% - 2rem);
        transform: translateX(0);
        margin-right: 0;
        width: max-content;
    }

    &__right {
        position: absolute;
        left: calc(100% - 2rem);
        bottom: calc(100% - 1rem);
        transform: translateX(0);
        margin-left: 0;
        width: max-content;
    }
}
