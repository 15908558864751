@import '@styles';

.social {
    @include spaces(g 8);
    display: flex;
    flex-direction: column;
    text-align: center;

    & > span {
        @include fonts(size 10, line 14);
        font-weight: 800;
    }

    & > div {
        @include spaces(g 8);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .button--gray-3.button--outline,
        .button--gray-3.button--text {
            @include palette(border accent 100);

            &:hover {
                @include palette(bg light accent);
            }
        }
    }

    &-media {
        @include spaces(p 8, b 1);
        border-color: palette(accent, 200) !important;
        border-radius: 50%;

        @include theme(dark) {
            border: none;
            background: palette(accent, 800) !important;
        }

        & > i {
            color: #1877f2;

            & > svg {
                @include spaces(w 16, h 16);
            }

            @include theme(dark) {
                @include palette(font accent 0);
            }
        }
    }
}
