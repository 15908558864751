@import '@styles';

.divider {
    @include opacity(border 5);
    @include palette(border accent 200);
    @include spaces(b 0.25, mx auto);
    display: flex;
    border-style: solid;
    width: 100%;

    @include theme(dark) {
        @include palette(border main base);
    }
}
