@import '@styles';

.slider-field {
    $hint: none;

    @include spaces(g 8, my 8);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;

    &__label {
        @include fonts(size 9, line 11);

        &:first-letter {
            text-transform: uppercase;
        }
    }

    &__track {
        display: flex;
        height: 1.5rem;
        width: 100%;

        &:focus {
            outline: none;
        }

        & > div {
            @include rounded(a 8);
            height: 1.25rem;
            width: 100%;
            align-self: center;

            /*  &::before {
                content: '';
                @include rounded(a 8);
                position: absolute;
                //@include palette(bg light gray-3);
                background-color: rgb(204, 204, 204);
                display: flex;
                flex-direction: column;
                height: 1.25rem;
                width: 3rem;
                left: 86.5%;
                z-index: -1;

                @include screen(xl) {
                    width: 5rem;
                    left: 85.5%;
                    z-index: -1;
                }
            } */

            /* &::after {
                content: '';
                @include rounded(a 8);
                position: absolute;
                //@include palette(bg light gray-3);
                background-color: #ffd200;
                display: flex;
                flex-direction: column;
                height: 1.25rem;
                width: 3rem;
                left: -3.5%;
                z-index: -1;

                @include screen(xl) {
                    left: -3%;
                }
            } */
        }
    }

    &__thumb {
        @include palette(bg light accent);
        @include rounded(a 4);
        @include spaces(g 2);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.75rem;
        width: 2.75rem;
        box-shadow: 0px 2px 6px #aaa;

        &:focus {
            outline: none;
        }

        & > div {
            @include palette(bg light gray-3);
            height: 1.5rem;
            width: 0.25rem;
        }

        &:hover {
            border-color: #aaa !important ;
        }
    }

    &__hint {
        $hint: &;

        @include palette(font light base-dark);
        @include fonts(size 6.5);
        @include spaces(my 1);
        font-weight: 500;
        z-index: 1;

        &:focus-within {
            #{$hint} {
                @include palette(font main base-dark);

                @include theme(dark) {
                    @include palette(font light gray);
                }
            }
        }
    }

    &__error {
        @include palette(font light danger);
        @include fonts(size 6.5);
        @include spaces(my 1);
        font-weight: 500;
        z-index: 1;
    }
}
