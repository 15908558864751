@import '@styles';

.notification {
    @include rounded(a 8);
    width: 100%;

    &__main {
        @include spaces(g 4);
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__content {
        @include spaces(g 6);
        display: flex;
        flex-direction: column;
    }

    &__icon {
        @include spaces(p 6);
        // @include spaces(w 20, h 20);
        aspect-ratio: 1;
        border-radius: 50%;

        // & > svg {
        //     @include spaces(w 12, h 12);
        // }

        &--error {
            @include palette(bg warning 100, font warning 500);

            @include theme(dark) {
                @include palette(bg main danger);
                @include opacity(bg 2);
            }
        }

        &--success {
            @include palette(bg success 100, font success 500);

            @include theme(dark) {
                @include palette(bg main success);
                @include opacity(bg 2);
            }
        }

        &--info {
            @include palette(bg secondary 100, font secondary 500);

            @include theme(dark) {
                @include palette(bg main secondary);
                @include opacity(bg 2);
            }
        }

        &--warning {
            @include palette(bg alert 100, font alert 500);

            @include theme(dark) {
                @include palette(bg main warning);
                @include opacity(bg 2);
            }
        }
    }

    &__title {
        font-weight: 600;
    }

    &__subtitle {
        @include palette(font accent 400);
        @include fonts(size 7);
    }

    &__close {
        @include spaces(pr 6);
        @include palette(font accent 700);

        @include theme(dark) {
            @include palette(font accent 0);
        }
    }
}

.Toastify__close-button {
    display: none;
}
