@import '@styles';

.modal-delete {
    @include palette(bg light accent, font main base-dark);
    @include spaces(g 8, p 16, m 8);
    @include rounded(a 16);
    //@include rounded(t 16);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include screen(lg) {
        @include spaces(m 8, lw 250, mw 250);
    }

    & > h1 {
        @include fonts(size 14, line 16);
        font-weight: 700;
        text-align: center;
    }

    & > i {
        @include spaces(b 1, p 8, w 50, h 50);
        @include palette(bg light base, border light gray-2);
        border-radius: 50%;
    }

    &__actions {
        @include spaces(g 8, pt 8);
        display: flex;
        width: 100%;

        a {
            text-decoration: none;
            width: 100%;

            .button--fill {
                width: 100%;
                box-shadow: 0 0 0 0;
            }
        }

        .button--fill {
            width: 100%;
            box-shadow: 0 0 0 0;
        }
    }

    &__scan {
        display: flex;
        justify-content: center;
        align-items: center;
        background: palette(light, warning-1) !important;
        border: 0.15rem solid palette(main, warning) !important;
        box-shadow: 0 0 0 0 !important;
        font-weight: 700;

        & > i {
            @include spaces(w 18, h 12);
        }
    }
}
