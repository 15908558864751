@import '@styles/index.scss';

.AppLoader {
    @include flex-vertical;
    @include full-screen;
    align-items: center;
    background-color: rgba($black, 0.45);
    color: $primary;
    justify-content: center;
    z-index: 10000;

    &:after {
        @include full-screen;
        content: '';
        display: block;
        pointer-events: none;
        z-index: 10001;
    }
}
