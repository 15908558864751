@import '@styles';

.sidebar {
    @include spaces(lw 156, mw 156);

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
    }

    &__header {
        @include spaces(py 8);
        @include palette(bg light base-dark);

        @include theme(dark) {
            @include palette(bg light base-dark);
        }
    }

    &__logo {
        @include spaces(mx auto, my 20);
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-weight: 700;

        @include fonts(size 30, line 32);

        & > img {
            overflow: hidden;
            object-fit: cover;
        }
    }

    &__wrapper {
        width: 100%;
    }

    &__options,
    &__others {
        @include palette(bg light base-dark);
        display: flex;
        flex-direction: column;

        @include theme(dark) {
            @include palette(bg light base-dark);
        }
    }

    &__options {
        @include spaces(pl 4);
        flex-grow: 1;
    }

    &__others {
        @include spaces(pl 4);
    }

    &__separator {
        @include spaces(ml 8, mr 12);
    }

    &__option {
        $option: &;

        @include spaces(px 20, my 6, py 6);
        @include palette(font light base);
        align-items: center;
        display: flex;
        flex-direction: row;
        width: 100%;
        text-decoration: none;

        &#{$option}--active {
            @include opacity(bg 10, border 10);
            @include palette(bg light primary, border dark primary);
            border-radius: 0.5rem;

            @include theme(dark) {
                @include palette(bg main base-dark, border light primary);
            }
        }

        &__icon {
            @include spaces(w 14, lw 14, mr 12);
        }

        &__text {
            @include fonts(size 10);
        }

        &__sub {
            @include spaces(w 10, lw 10, ml auto);
            transform: rotate(360deg);

            &--open {
                transform: rotate(180deg);
            }
        }

        &__accordion {
            flex-grow: 0;

            &__content {
                width: 100%;

                #{$option} {
                    @include spaces(pl 40);
                    width: 100%;
                }
            }
        }
    }
}
