@import '@styles';

.step-progress-bar {
    &__main {
        @include spaces(mx auto, my 4);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translateY(-50%);
            border-top: 4px solid #eaeaea;
            transition: 0.4s ease;
            border-style: dotted;
        }

        & > section {
            @include palette(bg light accent);
            @include spaces(px 12);
            z-index: 1;

            &:nth-of-type(1) {
                @include spaces(pr 12, pl 0);
            }

            &:nth-of-type(3) {
                @include spaces(pl 12, pr 0);
            }
        }
    }

    &__lineal {
        @include palette(bg main success);
        @include rounded(a 8);
        position: absolute;
        top: 50%;
        left: 0;
        height: 3px;
        transform: translateY(-50%);
        transition: 0.4s ease;
    }

    &__circle {
        @include palette(bg light base, font accent 500);
        @include spaces(w 28, h 28);
        @include fonts(size 10, line 12);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 0.1rem solid palette(light, gray);
        z-index: 1;
        transition: 0.4s ease;

        &--active {
            @include palette(bg main success, font light base);
            border-style: none;
        }

        &--check {
            @include palette(bg main success, font light base);
            border-style: none;
        }
    }
}
