/* Palette definition */
$palette-themes: (
    primary: (
        100: #fff9cc,
        200: #fff299,
        300: #FFE966,
        400: #FFE03F,
        500: #FFD200,
        600: #DBB000,
        700: #B79000,
        800: #937100,
        900: #7A5B00,
    ),
    secondary: (
        100: #E8FBD7,
        200: #CDF8B1,
        300: #A5EA86,
        400: #7ED563,
        500: #4BBA36,
        600: #319F27,
        700: #1B851B,
        800: #116B17,
        900: #0A5916,
    ),
    accent: (
        0: #ffffff,
        50: #f9fafb,
        100: #f3f4f6,
        200: #e5e7eb,
        300: #d1d5db,
        400: #9ca3af,
        500: #6b7280,
        600: #4b5563,
        700: #374151,
        800: #1f2a37,
        900: #111928,
    ),
    alert: (
        100: #fef3cc,
        500: #f99f02,
    ),
    info: (
        100: #cbf2fd,
        500: #0280e8,
    ),
    success: (
        100: #edfcd8,
        500: #68ce39,
    ),
    warning: (
        100: #ffe6d7,
        500: #ff443a,
    ),
    main: (
        secondary: #000,
        accent: #fff,
        info: #efefef,
        base: #f5f5f5,
        primary: #004581,
        base-dark: #111318,
        success: #4bba36,
        warning: #ffd200,
        danger: #e84d38,
        gray: #f3f3f3,
        gray-2: #e6e6e6,
        gray-3: #5a5a5a,
        gray-4: #7c7c7c,
    ),
    light: (
        base: #f4f4f4,
        base-dark: #1b1b1b,
        secondary: #000,
        accent: #fff,
        info: #efefef,
        primary: #62a5f8,
        success: #92da4e,
        success-1: #b3eda8,
        warning: #f6cb60,
        warning-1: #fff4c3,
        warning-2: #ffde42,
        danger: #e84d38,
        gray: #e6e6e6,
        gray-1: #d5d5d5,
        gray-2: #e6e6e6,
        gray-3: #bdbdbd,
        gray-4: #5f5f5f,
    ),
    dark: (
        primary: #003666,
        secondary: #000,
        accent: #000,
        base: #dbd4d1,
        base-dark: #08090c,
        info: #000,
        success: #92da4e,
        warning: #d59c0b,
        danger: #e60f0f,
        gray: #bcbfc8,
        gray-2: #888a96,
        gray-3: #4b4d58,
    ),
);

$palette-variation: 7;

$palette-apply: (
    bg: background-color,
    font: color,
    border: border-color,
);

$palette-by: 0.1;
$palette-base: 0;

@mixin palette-root {
    :root {
        @each $key, $prop in $palette-apply {
            --#{$brand}-#{$key}-opacity: 1;
        }
    }
}

@function palette($theme, $color, $function: null, $i: 0) {
    @if not map-get($palette-themes, $theme) {
        @error "No theme #{$theme} in palette";
    }

    @if not map-get(map-get($palette-themes, $theme), $color) {
        @error "No color #{$color} in palette";
    }

    @if ($i < 0) {
        @error "No level #{$i} in #{$color}";
    }

    @if ($i == 0) {
        @return map-get(map-get($palette-themes, $theme), $color);
    }

    @if $palette-variation * $i > 100 {
        @error "(variation * level) = #{$palette-variation * $i} cannot be greater than 100";
    }

    @return call(get-function($function), map-get(map-get($palette-themes, $theme), $color), $palette-variation * $i * 1%);
}

@function palette-light($theme, $color, $i) {
    @return palette($theme, $color, lighten, $i);
}

@function palette-dark($theme, $color, $i) {
    @return palette($theme, $color, darken, $i);
}

@mixin palette($keys...) {
    @each $key, $theme, $color, $variant, $level in $keys {
        @if not map-get($palette-apply, $key) {
            @error "No key #{$key} in palette apply";
        }

        @if $variant == light or $variant == l {
            @each $prop in map-get($palette-apply, $key) {
                #{$prop}: palette-light($theme, $color, $level);
                #{$prop}: rgba(palette-light($theme, $color, $level), var(--#{$brand}-#{$key}-opacity));
            }
        } @else if $variant == dark or $variant == d {
            @each $prop in map-get($palette-apply, $key) {
                #{$prop}: palette-dark($theme, $color, $level);
                #{$prop}: rgba(palette-dark($theme, $color, $level), var(--#{$brand}-#{$key}-opacity));
            }
        } @else {
            @each $prop in map-get($palette-apply, $key) {
                #{$prop}: palette($theme, $color);
                #{$prop}: rgba(palette($theme, $color), var(--#{$brand}-#{$key}-opacity));
            }
        }
    }
}

@mixin opacity($keys...) {
    @each $key, $opacity in $keys {
        @if $key == opacity {
            opacity: $palette-base + ($opacity * $palette-by);
        } @else {
            @if not map-get($palette-apply, $key) {
                @error "No key #{$key} in palette apply opacity";
            }

            @if $opacity < 0 or $opacity * $palette-by > 100% {
                @error "No opacity level #{$opacity} in #{$key}";
            }

            --#{$brand}-#{$key}-opacity: #{$palette-base + ($opacity * $palette-by)};
        }
    }
}
