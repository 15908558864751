@import '@styles';

.sponsor {
    @include palette(bg light accent, border light gray);
    @include spaces(p 32);
    @include rounded(a 16);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    width: 100%;
    box-shadow: 1px -2px 20px 4px palette(light, gray-1);

    @include screen(xl3) {
        width: 80%;
    }

    & > div {
        @include spaces(g 12, pt 8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        & > h2 {
            @include fonts(size 8, line 12);
            @include palette(font accent 500);
            width: 60%;
        }
    }

    &__logo {
        align-items: flex-start;

        & > svg {
            @include spaces(w 80, h 16);
        }
    }

    &__content {
        @include spaces(g 2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        & > h1 {
            @include fonts(size 18, line 20);
            font-weight: 600;
        }
    }

    &__icon {
        & > svg {
            width: 100%;
        }
    }
}
