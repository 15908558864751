@import '@styles';

.navbar {
    @include palette(bg main accent, font accent 800);
    @include spaces(mx auto, h 35);
    display: flex;
    width: 100%;

    @include screen(xl4) {
        @include spaces(mx auto, h 50);
    }

    &__header {
        @include spaces(mw 600, mx auto, h 35, px 12);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        text-transform: capitalize;
        width: 100%;

        @include screen(xl2) {
            @include spaces(mw 750);
        }

        @include screen(xl4) {
            @include spaces(mw 820, py 8, px 6, h 50);
        }
    }

    &__logo {
        justify-self: flex-start;
        cursor: pointer;

        & > svg {
            @include spaces(w 70, h 20);

            @include screen(xl4) {
                @include spaces(w 80, h 26);
            }
        }

        // &--cursor {
        //     cursor: pointer;
        // }
    }

    &__options {
        @include spaces(g 0);
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        list-style: none;

        @include screen(xl3) {
            @include spaces(g 6);
        }
    }

    &__option {
        @include palette(font accent 400);
        @include spaces(g 6, p 4);
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: bold;

        &:hover {
            @include palette(font accent 800);

            & > i {
                @include palette(font accent 800);
            }
        }

        & > i {
            @include spaces(w 14, h 14);
            @include palette(font accent 400);
        }

        &--active {
            opacity: 1;
            @include palette(font accent 800);

            & > i {
                @include palette(font accent 800);
            }

            &:hover {
                @include palette(font accent 800);

                & > i {
                    @include palette(font accent 800);
                }
            }
        }

        &--hidden {
            display: none;
        }

        &__select {
            @include spaces(g 4);
            display: flex;
            align-items: center;
            width: 100%;
            border-style: solid;

            .select__content .content__select--input {
                width: auto;
            }

            .select__content--disabled {
                opacity: 1;
            }

            .select__content {
                @include spaces(py 0);
                background: none !important;
                border: none;
            }
        }
    }

    &__profile {
        @include palette(font accent 400);
        @include spaces(g 4, p 4);
        display: flex;
        align-items: center;
        font-weight: bold;
        cursor: pointer;

        &--active {
            @include palette(font accent 800);
            opacity: 1;

            & > i {
                @include palette(font accent 800);
            }
        }

        &__arrow {
            @include fonts(size 12);
            transition: transform 0.3s;

            @include screen(xl4) {
                @include fonts(size 14);
            }

            &--rotate {
                transform: rotate(180deg);
            }
        }
    }
}
