@import '@styles';

.kyc-verification-modal {
    @include palette(bg accent 0, font accent 800);
    @include spaces(g 8, p 8, m 0);
    @include rounded(t 16, b 0);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include screen(xl) {
        @include spaces(m 8, p 16, lw 250, lh 250, mw 250, mh 250);
        @include rounded(a 16);
    }

    &__content {
        @include spaces(g 12, p 12);
        @include palette(font accent 800);
        display: flex;
        flex-direction: column;
        width: 100%;

        @include screen(xl) {
            @include spaces(p 12);
            @include rounded(a 16);
        }
    }

    &__title {
        @include spaces(g 8, pt 16);
        display: flex;
        flex-direction: column;
        width: 100%;

        & > h3 {
            @include fonts(size 14, line 16);
            font-weight: 700;
            text-align: center;
        }

        & > span {
            @include palette(font accent 500);
            @include fonts(size 10, line 12);
            text-align: center;
        }
    }
}