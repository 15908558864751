@import '@styles';

/* #tippy-4 {
    transform: translate(944px, 780px) !important;
} */

.tooltip-password {
    @include palette(bg accent 700);
    @include spaces(px 4, py 6);
    @include rounded(a 10);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: palette(accent, 700) !important;
    padding: 0.75rem 0.5rem !important;
    //border-radius: 0.25rem !important;
    width: 100%;

    .tippy-arrow {
        &::before {
            content: '';
            @include palette(font accent 700);
            //transform: translate(0px, 10px);
        }
    }

    &__content {
        @include spaces(g 6);
        display: flex;
        flex-direction: column;
        width: 100%;

        & > div {
            @include spaces(g 4);
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    &__message {
        @include palette(font main success);
        @include spaces(g 4);
        display: flex;
        width: 100%;

        &--danger {
            @include palette(font main danger);
        }
    }
}
