@import '@styles';

.chatbot {
    @include screen(xl) {
    }

    &__main {
        @include palette(bg light accent, font main base-dark);
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        @include screen(xl) {
            //@include spaces(mw 400, lw 400, mh 400, lh 400);
            @include spaces(m 8, g 8, p 8);
            @include rounded(a 8);
            width: 100%;
        }
    }

    &__title {
        @include spaces(pb 2);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    &__close {
        @include spaces(p 0);
        @include rounded(a 4);

        i {
            & > svg {
                @include spaces(w 14, h 14);
            }
        }
    }

    &__content {
        //@include spaces(pb 16);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
}
