@import '@styles';

.footer {
    display: none;

    @include screen(xl) {
        @include palette(bg light accent);
        @include spaces(px 24, pt 24, pb 12, g 10, mt auto);
        @include rounded(t 32);
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    & > i {
        @include spaces(w 100, h 25);
    }

    &__options {
        @include spaces(g 6, py 8);
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        list-style: none;

        @include screen(xl) {
            @include spaces(g 6, py 0);
            flex-direction: row;
        }

        & > li {
            .button {
                @include spaces(p 6, g 4);
                @include fonts(size 9, line 11);
                display: flex;
                align-items: center;
                box-shadow: 0 0 0 0;
                font-weight: 700;
            }
        }
    }

    &__option {
        @include palette(font accent 500);
        @include spaces(g 6, my 0, p 4);
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: 600;

        &:hover {
            @include palette(font main base-dark);
        }

        &--active {
            @include palette(font dark base-dark);
            font-weight: 800;

            &:hover {
                @include palette(font main base-dark);
            }
        }
    }

    &__social {
        @include spaces(g 8, pb 8);
        display: flex;
        flex-direction: column;
        align-items: center;

        & > span {
            @include palette(font dark primary);
        }

        & > div {
            @include spaces(g 8);
            display: flex;
            flex-direction: column;
            align-items: center;

            @include screen(xl) {
                @include spaces(g 12);
                flex-direction: row;
            }

            & > a {
                text-decoration: none;
            }
        }
    }

    &__social-media {
        @include spaces(g 12);
        display: flex;
        align-items: center;

        & > a {
            text-decoration: none;

            & > i {
                @include palette(font dark primary);
                @include fonts(size 16);
            }
        }
    }
}
