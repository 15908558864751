@import '@styles';

.hash-container {
    @include spaces(g 2);
    display: flex;
    flex-direction: column;
    width: 100%;

    &__label {
        @include fonts(line 24);
        cursor: pointer;
        text-align: justify;
        font-weight: 600;
    }

    &__main {
        @include spaces(g 8, p 6);
        @include rounded(a 4);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-style: solid;
        overflow: hidden;
        cursor: pointer;
        height: 4rem;

        &__copy {
            @include palette(font light base-dark);
            @include rounded(a 4);
            @include spaces(p 1, mr 2);
            @include fonts(size 12);

            &--bgapplied {
                &:hover {
                    @include palette(bg dark base);
                    @include rounded(a 4);
                    @include spaces(p 1, w 10);
                    box-shadow: 0 0 0.25rem palette(dark, base);
                    max-height: fit-content;

                    @include theme(dark) {
                        @include palette(bg dark base-dark);
                        box-shadow: 0 0 0.25rem palette(dark, base-dark);
                    }
                }
            }
        }

        &--bgapplied {
            @include palette(bg light base, border light gray);
            @include spaces(b 1);
            //@include spaces(px 8);

            @include theme(dark) {
                @include palette(bg light base-dark);
            }
        }

        & > div {
            @include spaces(g 4);
            display: flex;
            align-items: center;
            width: 80%;

            & > span {
                @include palette(font main base-dark);
                @include fonts(line 12);
                @include dots;
                text-align: justify;
                flex-grow: 1;
            }
        }
    }
}
