@import '@styles';

.modal-layout {
    bottom: 0px;
    display: flex;
    left: 0px;
    overflow: hidden;
    position: fixed;
    right: 0px;
    top: 0px;

    &--top {
        flex-direction: column;
    }
    &--bottom {
        flex-direction: column-reverse;
    }
    &--left {
        flex-direction: row;
    }
    &--right {
        flex-direction: row-reverse;
    }
    &--center {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    /* Opacity background */
    &__overlay {
        @include opacity(bg 6);
        @include palette(bg accent 800);
        inset: 0;
        position: absolute;

        @include theme(dark) {
            @include palette(bg accent 600);
        }

        &--dark {
            @include opacity(bg 10);
            @include palette(bg accent 700);
        }
    }

    /* To print as modal content */
    &__content {
        display: flex;
        overflow: hidden;
        z-index: 1;
    }
}
