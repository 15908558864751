@import '@styles';

.header-profile {
    @include spaces(g 12);
    display: flex;
    flex-direction: column;
    width: 100%;

    &--hidden {
        display: none;
    }

    &__title {
        @include palette(bg accent 800, font accent 0);
        @include spaces(px 16, pt 8, pb 18);
       // @include rounded(b 16);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @include screen(xl) {
            @include spaces(p 0);
            @include rounded(b 0);
            @include palette(font main base-dark);
            background: transparent;
        }

        & > h1 {
            @include fonts(size 10);
            font-weight: 700;

            @include screen(lg) {
                @include fonts(size 18, line 20);
            }
        }

        & > button {
            display: none;

            @include screen(xl) {
                @include fonts(size 8);
                @include spaces(px 10);
                display: flex;
                font-weight: bold;
            }
        }
    }

    &__divider {
        display: none;

        @include screen(xl) {
            @include opacity(border 5);
            @include palette(border accent 200);
            @include spaces(b 0.25, mx auto);
            display: flex;
            border-style: solid;
            width: 100%;

            @include theme(dark) {
                @include palette(border main base);
            }
        }
    }
}
