@import '@styles';

.modal-product {
    @include palette(bg light accent, font main base-dark);
    @include spaces(g 8, m 0);
    @include rounded(t 16, b 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include screen(xl) {
        @include spaces(m 8, g 8, p 8, lw 250, mw 250);
        @include rounded(a 16);
    }

    &__title {
        @include spaces(px 8);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    &__close {
        @include spaces(px 0, pb 0);
        @include rounded(a 4);

        @include screen(xl) {
            @include spaces(p 0); 
        }

        i {
            & > svg {
                @include spaces(w 14, h 14);
            }
        }
    }

    &__content {
        @include spaces(g 12, p 8);
        @include palette(font accent 800);
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;

        @include screen(xl) {
            align-items: center;
        }

        & > i {
            @include palette(bg light base);
            @include spaces(w 55, h 55, mx auto);
            border-radius: 50%;
            align-items: center;

            & > svg {
                @include spaces(w 80, h 60);
            }
        }

        & > h1 {
            @include fonts(size 14, line 16);
            font-weight: 700;
        }

        & > span {
            @include palette(font accent 600);
            @include fonts(size 9);
        }

        & > button {
            font-weight: 600;
        }
    }
}
