@import '@styles';

.tooltip {
    position: relative;
    //display: inline-flex;

    &__target {
        border: none;
        background: inherit;
        font-size: inherit;
        color: inherit;
        cursor: inherit;
        outline: transparent;
        width: 100%;
    }

    &__container {
        @include spaces(mx auto);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        bottom: calc(100% + 1rem);
        max-width: max-content;
        width: 100%;
        pointer-events: none;
        transform: translateX(-50%);
        z-index: 100;

        @include screen(xl) {
            width: 100%;
        }

        &__top {
            bottom: calc(100% + 5px);
        }

        &__bottom {
            bottom: unset !important;
            top: calc(100% + 5px);
        }

        &__left {
            left: unset;
            top: 50%;
            right: calc(100% + 1rem);
            transform: translateX(0);
            margin-right: 0;
            width: max-content;
        }

        &__right {
            top: 50%;
            left: calc(100% + 1rem);
            transform: translateX(0);
            margin-left: 0;
            width: max-content;
        }
    }

    &__content {
        @include palette(bg light base, font light base-dark);
        position: relative;
        text-align: center;
        padding: 10px 8px;
        border-radius: 5px;
        font-size: 1.25rem;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15), 0 4px 8px rgba(0, 0, 0, 0.2);

        &::after {
            content: '';
            position: absolute;
            left: calc(50% - 5px);
            top: 100%;
            width: 1rem;
            height: 1rem;
            border-width: .75rem;
            border-style: solid;
            border-color: palette(light, base) transparent transparent transparent;
        }

        /* &__top {
        } */

        &__bottom {
            &::after {
                top: unset;
                bottom: 100%;
                left: calc(50% - 5px);
                width: .75rem;
                border-color: transparent transparent palette(light, base) transparent;
            }
        }

        &__left {
            &::after {
                left: 100%;
                top: calc(50% - 5px);
                border-color: transparent transparent transparent palette(light, base);
            }
        }

        &__right {
            &::after {
                right: 100%;
                left: unset;
                top: calc(50% - 5px);
                color: #000;
                border-color: transparent palette(light, base) transparent transparent;
            }
        }
    }

    &__transition {
        &-enter {
            opacity: 0;
        }

        &-enter-active {
            transition: all 200ms;
            opacity: 1;
        }

        &-exit {
            opacity: 1;
        }

        &-exit-active {
            transition: all 200ms;
            opacity: 0;
        }
    }
}
