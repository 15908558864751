@import '@styles';

.drop-down-profile {
    @include palette(bg light accent, border accent 400);
    @include rounded(a 8);
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 0px 0px rgba(199, 199, 199, 0.1), 0px 6px 14px 0px rgba(199, 199, 199, 0.1), 0px 25px 25px 0px rgba(199, 199, 199, 0.09),
        0px 56px 34px 0px rgba(199, 199, 199, 0.05), 0px 100px 40px 0px rgba(199, 199, 199, 0.01), 0px 156px 44px 0px rgba(199, 199, 199, 0);
    position: absolute;
    //top: 4.5rem;
    transform: translate(-55%, 5%);
    width: 300px;
    z-index: 99;

    &__options {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        width: 100%;

        & > li {
            width: 100%;
        }
    }

    &__option {
        @include spaces(g 8, p 8);
        display: flex;
        align-items: center;
        text-decoration: none;
        border-bottom: 1px solid palette(accent, 200);
        cursor: pointer;

        & > span {
            font-weight: 600;
        }

        & > i {
            @include spaces(w 14, h 14);
            @include palette(font accent 600);
        }

        &--active {
            @include palette(font accent 800);
            opacity: 1;

            & > i {
                @include palette(font accent 800);
            }
        }

        &--hidden {
            display: none;
        }

        &--no-hidden {
            display: flex;
        }
    }
}
