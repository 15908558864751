@import '@styles';

.top-bar {
    background: #fff9cc;

    &--background {
        background: #fff9cc;
    }

    &--full {
        @include palette(bg accent 800, font accent 0);
    }

    &__main {
        @include spaces(g 8, py 16, px 12);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        h3 {
            @include fonts(size 12, line 14);
            font-weight: 700;
            cursor: default;
            text-align: center;
        }
    }

    &__icon-left,
    &__icon-right {
        cursor: pointer;
        
        & > svg {
            @include spaces(w 18, h 18);
        }
        // & > i {
        //
        // }
    }

    &__icon-left {
        @include spaces(ml auto);
    }

    &__icon-right {
        @include spaces(mr auto);
    }

    &__logo {
        cursor: pointer;
        
        & > svg {
            @include spaces(w 70, h 20);
        }
    }
}
