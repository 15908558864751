@import '@styles';

.radio-button {
    @include spaces(g 8, my 4);
    display: flex;
    align-items: center;

    &__label {
        @include fonts(size 10, line 12);
        @include palette(font light base-dark);

        &--selected {
            font-weight: 700;
        }
    }

    input[type='radio'] {
        -webkit-appearance: none;
        appearance: none;
        @include palette(border light gray);
        @include rounded(a 16);
        margin: 0;
        width: 2.25rem;
        height: 2.25rem;
        border: 0.1rem solid palette(light, gray-1);
        transition: border-color 0.2s ease-in-out;

        &::after {
            content: '';
            @include rounded(a 8);
            display: inline-block;
            position: relative;
            width: 1.3rem;
            height: 1.3rem;
            left: 6px;
            top: 6px;
        }

        &:checked {
            &::after {
                @include palette(bg main success);
            }

            &:hover {
                //@include palette(bg light base, border light gray);

                &::after {
                    @include palette(bg main success);
                }
            }
        }
    }
}
