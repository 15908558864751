@import '@styles';

$apply-text-field-roles: primary success warning danger;

// label
@mixin label-roles($label) {
    #{$label} {
        @include palette(font light base-dark);
        //@include palette(font main gray-2);

        @include theme(dark) {
            @include palette(font light gray-3);
        }
    }

    &:focus-within {
        #{$label} {
            @include palette(font light base-dark);
            //@include palette(font main gray-3);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$label} {
            @include palette(font light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font light danger);
            }
        }

        &:focus-within {
            #{$label} {
                @include palette(font light danger);
                //@include palette(font main danger);

                @include theme(dark) {
                    @include palette(font light danger);
                }
            }
        }
    }
}

// content
@mixin content-roles($content) {
    #{$content} {
        @include palette(bg accent 100, border accent 300);

        @include theme(dark) {
            @include palette(bg accent 600, border light base-dark);
        }

        &-ci,
        &-si,
        &-bi,
        &-ai,
        &-pass {
            @include palette(font light base-dark);
        }

        &-ci:hover,
        &-pass:hover,
        &-select:hover,
        &__modal:hover + .text-field__content-select,
        &-ai--interactive:hover {
            cursor: pointer;

            svg {
                @include palette(bg dark base);
                @include rounded(a 2);
                @include spaces(p 1);
                box-shadow: 0 0 0.25rem palette(dark, base);
                max-height: fit-content;
                //transform: scale(1.3);

                @include theme(dark) {
                    @include palette(bg dark base-dark);
                    box-shadow: 0 0 0.25rem palette(dark, base-dark);
                }
            }
        }
    }

    &:focus-within {
        #{$content} {
            @include palette(border secondary 300);
            box-shadow: 0px 0px 5px palette(secondary, 300);

            @include theme(dark) {
                @include palette(border secondary 400);
            }

            &-ci,
            &-si,
            &-bi,
            &-ai,
            &-pass {
                @include palette(font light base-dark);
                //@include palette(font main gray-3);

                @include theme(dark) {
                    @include palette(font light gray);
                }
            }
        }
    }

    &--#{danger} {
        #{$content} {
            @include palette(border warning 100);
            box-shadow: 0px 0px 5px palette(warning, 100);
            //@include palette(font warning 100);

            @include theme(dark) {
                @include palette(border warning 100);
            }
        }

        &:focus-within {
            #{$content} {
                @include palette(border main secondary);
                box-shadow: 0px 0px 10px palette(warning, 100);

                @include theme(dark) {
                    @include palette(border secondary 800);
                }
            }
        }
    }

    @each $key in $apply-text-field-roles {
        &--#{$key} #{$content}--keep {
            @include palette(border main $key);
            //@include palette(border main $key);

            @include theme(dark) {
                @include palette(border main $key);
            }
        }

        &--#{$key}:focus-within {
            #{$content} {
                @include palette(border main $key);

                @include theme(dark) {
                    @include palette(border main $key);
                }

                &-ci,
                &-si,
                &-bi,
                &-ai,
                &-pass {
                    @include palette(font light $key);
                    //@include palette(font dark $key);

                    @include theme(dark) {
                        @include palette(font light $key);
                    }
                }
            }
        }
    }
}

// hint
@mixin hint-roles($hint) {
    #{$hint} {
        @include palette(font light base-dark);
        //@include palette(font main gray-3);

        @include theme(dark) {
            @include palette(font light gray-2);
        }
    }

    &:focus-within {
        #{$hint} {
            @include palette(font main base-dark);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$hint} {
            @include palette(font light danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font light danger);
            }
        }
    }

    @each $key in $apply-text-field-roles {
        &--#{$key}:focus-within {
            #{$hint} {
                @include palette(font light $key);
                //@include palette(font main $key);

                @include theme(dark) {
                    @include palette(font light $key);
                }
            }
        }
    }
}

.text-field {
    $parent: &;
    $label: none;
    $content: none;
    $hint: none;

    align-items: flex-start;
    border-style: none;
    display: flex;
    flex-direction: column;
    position: relative;

    &--fit {
        max-width: fit-content;
    }

    /* &:disabled {
        opacity: 0.6;
    } */

    &__label {
        $label: &;

        @include fonts(line 20);
        cursor: pointer;
        font-weight: 500;

        &--switch {
            @include spaces(m 0, h 16, w 32);
            display: inline-block;
            position: relative;

            & > input {
                @include palette(bg accent 500, border accent 300);
                height: 0;
                opacity: 0;
                width: 0;
            }

            & > span {
                @include palette(bg accent 500, border accent 300);
                @include rounded(a 8);
                @include spaces(b 1);
                border-style: solid;
                bottom: 0;
                cursor: pointer;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition: 0.125s;

                @include theme(dark) {
                    @include palette(bg accent 200, border accent 300);
                }

                &:before {
                    @include palette(bg accent 100);
                    //@include palette(bg main gray-2);
                    @include rounded(a 16);
                    @include spaces(h 12, w 12);
                    bottom: 0.2rem;
                    content: '';
                    left: 0.25rem;
                    position: absolute;
                    transition: 0.4s;

                    @include theme(dark) {
                        @include palette(bg main gray-3);
                    }
                }
            }

            input:checked + span {
                @include palette(bg light success-1, border light success-1);
            }

            input:checked + span:before {
                @include palette(bg main success);
                transform: translateX(1.85rem);
            }
        }
    }

    &__content {
        $content: &;

        @include rounded(a 8);
        @include spaces(b 1);
        border-style: solid;
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 4rem;

        &--disabled {
            opacity: 0.6;
        }

        &--background {
            background: transparent !important;
            border: none;
            box-shadow: none;
            //width: 45%;

            & > input {
                @include spaces(ml 8);
                @include palette(font light base-dark);
                @include fonts(size 24, line 28);
                font-weight: 800;
                background: transparent;
                border: none;
                box-shadow: none;

                &:focus {
                    border: none;
                    box-shadow: none;
                }
            }

            &-bi {
                @include palette(font light base-dark);
                @include fonts(size 24, line 28);
                font-style: normal;
                font-weight: 800;
            }

            &-ai {
                @include palette(font main gray-4);
                display: flex;
                align-items: center;
            }
        }

        & > div {
            .react-datepicker__input-container {
                inset: 0;
                position: absolute;

                & > div {
                    cursor: pointer;
                    height: 100%;
                    width: 100%;
                }
            }

            button {
                @include palette(bg main gray-2);
                @include rounded(a 4);
                @include spaces(w 16, h 16, p 0, m 0);
                top: 50%;
                left: 0;
                transform: translateY(-50%) translateX(25%);

                &::after {
                    @include fonts(size 16);
                    background-color: transparent;
                    display: inline;
                }
            }
        }

        span {
            @include fonts(line 14);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        input,
        span {
            @include fonts(line 14);
            @include palette(font main base-dark);
            @include spaces(p 6);
            background: transparent;
            border-style: none;
            outline: none;
            flex-grow: 1;
            width: 0;

            @include theme(dark) {
                @include palette(font main gray);
            }
        }

        span {
            display: flex;
            align-items: center;
            width: 80%;
            position: absolute;
            @include spaces(ml 18);
            top: 0.25rem;
        }

        input::placeholder {
            @include palette(font accent 400);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        input[type='number'] {
            -moz-appearance: textfield;
        }
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        input[type='checkbox'] {
            @include opacity(border 10);
            @include palette(bg accent 300, border accent 300);
            @include rounded(a 6);
            appearance: none;
            border-style: solid;

            @include screen(xl) {
                @include palette(bg accent 200, border accent 300);
            }

            &:checked {
                @include palette(bg main success);
                box-shadow: 0 0 0.25rem palette(main, success);
                position: relative;
                width: 100%;
                height: 100%;

                &::after {
                    @include palette(font main accent);
                    @include fonts(size 10, line 12);
                    content: '✓';
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    font-weight: 800;
                    transform: translateX(-50%) translateY(-50%);
                    -webkit-transform: translateX(-50%) translateY(-50%);
                }
            }
        }

        &-ci,
        &-si,
        &-bi,
        &-ai,
        &-pass {
            @include spaces(w 10);
        }

        &-ai--interactive {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &-ci {
            @include spaces(mr 8);
        }

        &-ci,
        &-bi {
            @include spaces(ml 8);
        }

        &-ai,
        &-pass {
            @include spaces(mr 8);
        }

        &-pass {
            cursor: pointer;
        }

        &__file,
        &__select {
            display: none;
        }

        &__modal {
            cursor: pointer;
            inset: 0;
            position: absolute;
        }

        &--hidden {
            display: none;
        }
    }

    &--checkbox {
        @include opacity(border 0);
        cursor: pointer;

        & * {
            cursor: pointer;
        }

        #{$content} {
            @include rounded(a 6);
        }
    }

    &__hint {
        $hint: &;

        @include fonts(size 6.5);
        @include spaces(my 1);
        font-weight: 500;
        z-index: 1;
    }

    &__reserved {
        @include spaces(h 10, mt 1);
    }

    &:disabled {
        #{$label},
        .react-datepicker__input-container > div,
        #{$content}-pass,
        #{$content}__modal {
            cursor: auto;
        }
    }

    &#{$parent}--file {
        //@include palette(border light gray, bg light base);
        @include rounded(a 4);
        @include spaces(b 1, p 12);
        align-items: center;
        border-style: solid;
        justify-content: center;
        overflow: hidden;
        height: 100%;

        @include theme(dark) {
            @include palette(border light primary);
        }

        #{$label} {
            //@include palette(font main base);
            @include palette(font main base-dark);
            //@include spaces(m 0);
            @include fonts(line 8);
            align-items: center;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            font-weight: 400;
            justify-content: center;
            width: 100%;

            @include theme(dark) {
                @include palette(font main base);
            }

            &__preview {
                object {
                    height: 100%;
                    inset: 0;
                    object-fit: cover;
                    position: absolute;
                    width: 100%;
                }

                button {
                    @include spaces(p 4);
                    position: absolute;
                    right: 0;
                    top: 0;

                    i {
                        @include spaces(w 16, p 0);
                    }
                }
            }

            i {
                @include spaces(px 8);
                max-height: 8rem;
                max-width: 8rem;
                width: 100%;
            }
        }

        #{$content} {
            @include palette(border light gray, bg light base);
            //display: none;
        }

        #{$hint} {
            @include spaces(m 0);
        }
    }

    // label
    @include label-roles($label);
    // content
    @include content-roles($content);
    // hint
    @include hint-roles($hint);
}
