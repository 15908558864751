@import '@styles';

.contact {
    @include spaces(g 8, px 12, pb 12);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    @include screen(xl) {
        @include spaces(my 8, py 12);
    }

    & > span {
        @include palette(font light gray-3);
        display: flex;

        @include screen(xl) {
            display: none;
        }
    }

    &__detail {
        @include spaces(pt 8, g 12);
        @include rounded(a 8);
        display: flex;
        flex-direction: column;

        @include screen(xl) {
            @include palette(bg light base);
            @include spaces(p 10, g 12);
        }
    }

    &__support {
        @include spaces(g 10);
        display: flex;
        align-items: center;

        & > i {
            @include fonts(size 14);
        }

        & > div {
            @include spaces(g 4);
            display: flex;
            flex-direction: column;

            & > span {
                @include palette(font light gray-3);
            }

            & > h3,
            & > a {
                @include fonts(size 12, line 16);
                text-decoration: none;
                font-weight: 800;
            }
        }
    }
}
