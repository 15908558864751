@import '@styles';

$apply-date-field-roles: primary success warning danger;

// label
@mixin label-roles($label) {
    #{$label} {
        @include palette(font light base-dark);
        //@include palette(font main gray-2);

        @include theme(dark) {
            @include palette(font accent 400);
        }
    }

    &:focus-within {
        #{$label} {
            @include palette(font light base-dark);
            //@include palette(font main gray-3);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$label} {
            @include palette(font main danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font main danger);
            }
        }

        &:focus-within {
            #{$label} {
                @include palette(font main danger);
                //@include palette(font main danger);

                @include theme(dark) {
                    @include palette(font main danger);
                }
            }
        }
    }
}

// content
@mixin content-roles($content) {
    #{$content} {
        @include palette(bg accent 100, border accent 300);

        @include theme(dark) {
            @include palette(bg accent 600, border light base-dark);
        }
    }

    &:focus-within {
        #{$content} {
            @include palette(border secondary 300);
            box-shadow: 0px 0px 5px palette(secondary, 300);

            @include theme(dark) {
                @include palette(border secondary 400);
            }
        }
    }

    &--#{danger} {
        #{$content} {
            @include palette(border warning 100);
            box-shadow: 0px 0px 5px palette(warning, 100);
            //@include palette(font warning 100);

            @include theme(dark) {
                @include palette(border warning 100);
            }
        }

        &:focus-within {
            #{$content} {
                @include palette(border main secondary);
                box-shadow: 0px 0px 10px palette(warning, 100);

                @include theme(dark) {
                    @include palette(border secondary 800);
                }
            }
        }
    }

    @each $key in $apply-date-field-roles {
        &--#{$key} #{$content}--keep {
            @include palette(border main $key);
            //@include palette(border main $key);

            @include theme(dark) {
                @include palette(border main $key);
            }
        }

        &--#{$key}:focus-within {
            #{$content} {
                @include palette(border main $key);

                @include theme(dark) {
                    @include palette(border main $key);
                }
            }
        }
    }
}

// hint
@mixin hint-roles($hint) {
    #{$hint} {
        @include palette(font light base-dark);
        //@include palette(font main gray-3);

        @include theme(dark) {
            @include palette(font accent 400);
        }
    }

    &:focus-within {
        #{$hint} {
            @include palette(font main base-dark);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$hint} {
            @include palette(font main danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font main danger);
            }
        }
    }

    @each $key in $apply-date-field-roles {
        &--#{$key}:focus-within {
            #{$hint} {
                @include palette(font main $key);
                //@include palette(font main $key);

                @include theme(dark) {
                    @include palette(font main $key);
                }
            }
        }
    }
}

.date-field {
    $parent: &;
    $label: none;
    $content: none;
    $hint: none;

    align-items: flex-start;
    border-style: none;
    display: flex;
    flex-direction: column;
    position: relative;

    &--fit {
        max-width: fit-content;
    }

    /* &:disabled {
        opacity: 0.6;
    } */

    &__label {
        $label: &;

        @include fonts(line 20);
        cursor: pointer;
        font-weight: 500;
    }

    &__content {
        $content: &;

        @include rounded(a 8);
        @include spaces(b 1);
        border-style: solid;
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 4rem;

        .ant-picker-input {
            input::placeholder {
                @include fonts(size 8);
                @include palette(font accent 400);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-family: 'Plus Jakarta Sans', sans-serif;
            }
        }

        &__popup {
            .ant-picker-panel {
                @include theme(dark) {
                    @include palette(bg accent 800, font accent 0);
                }
            }

            .ant-picker-header,
            .ant-picker-header-super-prev-btn,
            .ant-picker-prev-icon,
            .ant-picker-header-next-btn,
            .ant-picker-next-icon,
            .ant-picker-header-super-next-btn,
            .ant-picker-content,
            .ant-picker-dropdown .ant-picker-content,
            .ant-picker-footer {
                @include theme(dark) {
                    color: palette(accent, 0) !important;
                }

                th,
                td {
                    @include theme(dark) {
                        color: palette(accent, 0) !important;
                    }
                }
            }
        }
    }

    &__hint {
        $hint: &;

        @include fonts(size 6.5);
        @include spaces(my 1);
        font-weight: 500;
        z-index: 1;
    }

    &__reserved {
        @include spaces(h 10, mt 1);
    }

    &:disabled {
        #{$label} {
            cursor: auto;
        }
    }

    // label
    @include label-roles($label);
    // content
    @include content-roles($content);
    // hint
    @include hint-roles($hint);
}
