@import '@styles';

.qr-code {
    flex-grow: 1;

    @include screen(lg) {
        flex-grow: 0;
    }

    &__main {
        @include palette(bg accent 0, font accent 800);
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        width: 100%;
        height: 100vh;

        @include screen(lg) {
            @include spaces(mw 225, lw 225);
            @include spaces(m 8, g 8, py 8);
            @include rounded(a 8);
            width: 100%;
            height: auto;
        }

        // @include screen(xl) {
        //     @include spaces(mh 360, lh 360);

        // }
    }

    &__close {
        @include spaces(px 8);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    &__content {
        @include spaces(g 16, p 10);
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;
        width: 100%;
    }

    &__title {
        @include spaces(g 16);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        & > h1 {
            @include fonts(size 14);
            font-weight: 800;
        }

        & > h2 {
            @include palette(font accent 500);
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60%;
        height: auto;
    }

    &__detail {
        @include palette(bg accent 800, font accent 0);
        @include spaces(p 10, g 10);
        @include rounded(a 10);
        display: flex;
        flex-direction: column;
        width: 100%;

        & > div {
            @include spaces(g 4);
            display: flex;
            flex-direction: column;
            width: 100%;

            & > h3 {
                @include fonts(size 9);
                font-weight: 700;
            }
        }
    }
}
