@import '@styles';

.empty-state {
    @include spaces(g 8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    & > div {
        @include spaces(g 8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        width: 60%;

        & > h3 {
            @include fonts(size 9, line 11);
            font-weight: 800;
            text-align: center;

            @include screen(xl) {
                @include fonts(size 14, line 18);
            }
        }

        & > span {
            @include palette(font accent 500);
            font-weight: 600;
            width: 60%;
            text-align: center;

            @include screen(xl) {
                @include fonts(size 10, line 12);
            }
        }

        & > button {
            width: 80%;
        }

        .button--fill {
            box-shadow: 0 0 0 0;
        }
    }
}
