@import '@styles';

.dashboard {
    &__main {
        @include palette(bg light accent);
        //@include palette(bg light base, font main base-dark);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
        height: 100vh;
        -webkit-overflow-scrolling: touch;
    }

    &__sidebar {
        display: none !important;

        @include screen(xl) {
            display: flex !important;
        }
    }

    &__mobile-navbar,
    &__mobile-bottombar {
        @include screen(xl) {
            display: none !important;
        }
    }

    &__content {
        background: linear-gradient(to bottom, #fff9cc 0%, #fff9cc 30%, #f9fafb 30%, #f9fafb 100%);
        position: relative;
        width: 100%;

        &::before {
            @include screen(xl) {
                content: '';
                opacity: 0;
                position: absolute;
                top: -5%;
                left: 75%;
                background: #ffd200;
                width: 300px;
                height: 300px;
                border-radius: 50%;
                aspect-ratio: 1;
            }
        }

        // &--mobile {
        //     @include spaces(pb 60);
        // }

        // &--tablet {
        //     @include spaces(pb 32);
        // }
    }

    &__container {
        @include palette(bg accent 0);
        //@include rounded(t 16);
        position: relative;

        @include screen(xl) {
            @include rounded(t 0);
            background: transparent;
        }

        // &--mobile {
        //     @include spaces(pb 1);
        // }

        // &--tablet {
        //     @include spaces(pb 1);
        // }
    }

    &__children {
        @include spaces(g 10, p 0);

        @include screen(xl) {
            @include spaces(p 16, pb 0, mw 750, mx auto);
        }

        @include screen(xl4) {
            @include spaces(mw 850);
        }
    }

    &__whatsapp {
        display: none;

        @include screen(xl) {
            display: block;
            position: fixed;
            width: 60px;
            height: 60px;
            line-height: 63px;
            bottom: 25px;
            left: 25px;
            background: #25d366;
            color: #fff;
            border-radius: 50px;
            text-align: center;
            font-size: 35px;
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.3);
            z-index: 100;
            transition: all 300ms ease;

            &:hover {
                background: #20ba5a;
            }
        }
    }
}
