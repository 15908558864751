@import '@styles';

.mobile-bottombar {
    width: 100%;
    position: relative;
    text-transform: capitalize;

    &__options {
        @include palette(bg accent 0);
        @include spaces(px 10, pt 10, pb 16, g 8);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: 100%;
        list-style: none;

        &--grid {
            grid-template-columns: repeat(6, 1fr);
        }

        @include screen(xl) {
            @include spaces(p 10);
        }
    }

    &__option {
        @include palette(font accent 400);
        @include spaces(g 4);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;

        & > div {
            @include spaces(w 22, h 4);
            background: none;
            position: absolute;
            top: -0.1rem;
        }

        & > i {
            @include spaces(w 16, h 16);
            @include palette(font accent 400);
        }

        &:hover {
            @include palette(font light base-dark);
            font-weight: 600;

            i {
                @include palette(font accent 500);
            }
        }

        &--active {
            opacity: 1;
            @include palette(font main base-dark);
            font-weight: 700;

            & > i {
                @include palette(font main base-dark);
            }

            &:hover {
                @include palette(font main base-dark);

                & > i {
                    @include palette(font main base-dark);
                }
            }
        }

        &--hidden {
            display: none;
        }

        &--no-hidden {
            display: flex;
        }
    }
}
