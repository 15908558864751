@import '@styles';

.loading-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;

    & > i {
        position: absolute;
        transform: translate(0%, 0%);
    }

    &::after {
        content: '';
        @include spaces(w 100, h 100);
        border: 1.25rem solid #dddddd;
        border-top-color: palette(main, success);
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border-radius: 50%;
        transform: rotate(0.16turn);
        animation: loading 1s ease infinite;
    }

    @keyframes loading {
        /* Safari support */
        from {
            transform: rotate(0turn);
        }

        to {
            transform: rotate(1turn);
        }
    }
}
