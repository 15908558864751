@import '@styles';

$apply-otp-field-roles: primary success warning danger;

// label
@mixin label-roles($label) {
    #{$label} {
        @include palette(font light base-dark);
        //@include palette(font main gray-2);

        @include theme(dark) {
            @include palette(font accent 400);
        }
    }

    &:focus-within {
        #{$label} {
            @include palette(font light base-dark);
            //@include palette(font main gray-3);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$label} {
            @include palette(font main danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font main danger);
            }
        }

        &:focus-within {
            #{$label} {
                @include palette(font main danger);
                //@include palette(font main danger);

                @include theme(dark) {
                    @include palette(font main danger);
                }
            }
        }
    }
}

// content
@mixin content-roles($content) {
    #{$content} {
        @include palette(bg light accent, border accent 300);

        @include theme(dark) {
            @include palette(bg light base-dark, border light base-dark);
        }
    }

    &:focus-within {
        #{$content} {
            @include palette(border main secondary);
            box-shadow: 0px 0px 5px palette(main, secondary);
            outline: none;

            @include theme(dark) {
                @include palette(border primary 500);
            }
        }
    }

    &--#{danger} {
        #{$content} {
            @include palette(border main danger);
            box-shadow: 0px 0px 5px palette(main, danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(border main danger);
            }
        }

        &:focus-within {
            #{$content} {
                @include palette(border main secondary);
                box-shadow: 0px 0px 10px palette(main, danger);

                @include theme(dark) {
                    @include palette(border primary 500);
                }
            }
        }
    }

    @each $key in $apply-otp-field-roles {
        &--#{$key} #{$content}--keep {
            @include palette(border main $key);
            //@include palette(border main $key);

            @include theme(dark) {
                @include palette(border main $key);
            }
        }

        &--#{$key}:focus-within {
            #{$content} {
                @include palette(border main $key);

                @include theme(dark) {
                    @include palette(border main $key);
                }
            }
        }
    }
}

// hint
@mixin hint-roles($hint) {
    #{$hint} {
        @include palette(font light base-dark);
        //@include palette(font main gray-3);

        @include theme(dark) {
            @include palette(font accent 400);
        }
    }

    &:focus-within {
        #{$hint} {
            @include palette(font main base-dark);

            @include theme(dark) {
                @include palette(font light gray);
            }
        }
    }

    &--#{danger} {
        #{$hint} {
            @include palette(font main danger);
            //@include palette(font main danger);

            @include theme(dark) {
                @include palette(font main danger);
            }
        }
    }

    @each $key in $apply-otp-field-roles {
        &--#{$key}:focus-within {
            #{$hint} {
                @include palette(font main $key);
                //@include palette(font main $key);

                @include theme(dark) {
                    @include palette(font main $key);
                }
            }
        }
    }
}

.otp-field {
    $parent: &;
    $label: none;
    $content: none;
    $hint: none;

    align-items: flex-start;
    border-style: none;
    display: flex;
    flex-direction: column;
    position: relative;
    outline: none;

    &--fit {
        max-width: fit-content;
    }

    /* &:disabled {
        opacity: 0.6;
    } */

    &__label {
        $label: &;

        @include fonts(line 20);
        cursor: pointer;
        font-weight: 600;
    }

    &__container {
        display: flex;
        width: 100%;
        max-width: 380px;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
    }

    &__content {
        $content: &;
        @include fonts(size 12, line 16);
        @include rounded(a 4);
        @include spaces(b 1);
        border-style: solid;
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 3.65rem;
        font-weight: 700;
        text-align: center;

        &--disabled {
            opacity: 0.6;
        }

        &__separator {
            @include opacity(border 8);
            @include rounded(b 8);
            @include palette(border accent 500);
            @include spaces(bb 1.5, mx auto);
            display: flex;
            border-style: solid;
            width: 100%;

            @include theme(dark) {
                @include palette(border main base);
            }
        }

        input::placeholder {
            @include palette(font accent 400);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &--hidden {
            display: none;
        }
    }

    &__hint {
        $hint: &;

        @include fonts(size 6.5);
        @include spaces(my 1);
        font-weight: 500;
        z-index: 1;
    }

    &__reserved {
        @include spaces(h 10, mt 1);
    }

    // label
    @include label-roles($label);
    // content
    @include content-roles($content);
    // hint
    @include hint-roles($hint);
}
